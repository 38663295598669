.App {
  text-align: center;
}

body {
  font-family: 'ClashGrotesk-Semibold';
  font-size: 14px;
  background: #fff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.container {
  max-width: 1240px !important;
}


#loader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  color: var(--default);
  z-index: 999999;
  opacity: 1;
  pointer-events: none;
  transition: all 60s ease-in-out;
  /* opacity: 0.8; */
}

.flip-container {
  -webkit-perspective: 1000;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.usrlog:hover {
  text-decoration: none;
}

.dashboardbtn {
  margin-right: 10px !important;
}

.eyeicon {
  float: right;
  text-align: right;
  cursor: pointer;
}

.pagination {
  justify-content: end;
}

.pagination a {
  padding: 5px 10px;
}

.pagination .previous a {
  text-decoration: none;
}

.pagination .next a {
  text-decoration: none;
}

.pagination li {
  margin-right: 5px;
}

.pagination li.selected {
  background-color: #2D70A1;
  color: white;
  border-radius: 5px;
}

.pagination a:hover:not(.selected) {
  background-color: #ddd;
  border-radius: 5px;
}

.readmorecls {
  color: red;
  cursor: pointer;
}

.text-left {
  text-align: left;
}

.Toastify__close-button {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.resourceimg {
  height: 200px;
  width: 100%;
  object-fit: cover;
  border: 1px solid;
}


.display-none {
  display: none !important;
}

.display-block {
  display: block !important;
}

.dashboardcardarea .title {
  float: left;
}

.dashboardcardarea .btn {
  float: right;
  color: #fff;
  border: 1px solid;
}

.rightprofile .coursedetailsbtn {
  padding: 8px;
  border: none;
  border-radius: 50px;
  font-size: 14px;
  background: linear-gradient(134deg, #2D70A1 0%, #60A3C5 100%);
  color: #fff;
  border: 2px solid #fff;
  position: relative;
  font-family: 'ClashGrotesk-Medium';
  text-decoration: none;
}

.nodataavailable {
  font-weight: 600;
  color: #000;
}

.fieldlabel {
  float: left;
  font-weight: 600;
  color: #000;
}

.downarrowcls {
  font-size: 20px;
  font-weight: 600;
}

.footerwhatsapp {
  font-weight: 600;
}

.skiptranslate iframe {
  display: none;
}

.bodycls {
  top: 0px !important;
}

.googletranslateelement {
}

.googletranslateelement select {
  border-radius: 14px;
  font-family: 'ClashGrotesk-Medium';
}