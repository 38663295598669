@media (min-width: 1920px) {
	.container {
		max-width: 1600px;
		width: auto;
	}


}

@media (max-width: 1800px) {
	.container {
		max-width: 1580px;
	}

	.headercontainer .navigation .sf-menu>li {
		margin: 0px 15px;
	}

	.headercontainer .navigation .sf-menu>li a {
		font-size: 16px;
	}

	.headercontainer .logoouter {
		max-width: 230px;
	}

	.baner-cntent {
		max-width: 660px;
	}

	.bnrsrch input {
		max-width: 272px;
	}

	.video-card .card::before {
		height: 52%;
	}

	.containercls {
		max-width: 1340px !important;
	}
}

@media (max-width: 1700px) {
	.container {
		max-width: 1320px;
	}

	.containercls {
		max-width: 1340px !important;
	}

	.fotlink {
		padding-left: 30px;
	}

	.breadcrumb-item a {
		font-size: 16px;
	}

	.breadcrumb-item+.breadcrumb-item {
		font-size: 16px;
	}

	.video-card .card::before {
		height: 51%;
	}

	.instruction h4 {
		font-size: 40px;
	}

	.course-name h2 {
		font-size: 40px;
	}

	.course-detail h4 {
		font-size: 40px;
	}

	.course-name h6,
	.resources h6,
	.Objectives h6 {
		font-size: 26px;
	}

	.Objectives-list ul li {
		font-size: 16px;
		margin-bottom: 16px;
	}

	.video-card .card-title {
		font-size: 26px;
		line-height: 30px;
	}

	.video-card .card-body p {
		font-size: 16px;
	}

	.video-card .card-body a {
		font-size: 20px;
		height: 65px;
	}

	.personal-goal h6,
	.Case-exercise h6,
	.work-book h6,
	.Virtual-mentoring h6 {
		font-size: 24px;
	}

	.personal-goal p,
	.Case-exercise p,
	.book p,
	.Virtual-mentoring p {
		font-size: 16px;
	}

}

@media (min-width: 1599px) {
	.containercls {
		max-width: 1340px !important;
	}

}

@media (max-width: 1499px) {}

@media (max-width: 1399px) {
	.container {
		max-width: 1240px;
	}

	.headercontainer .navigation .sf-menu>li {
		margin: 0px 14px;
	}

	.headercontainer .logoouter {
		max-width: 200px;
	}

	.headercontainer {
		padding: 15px 0px;
	}

	.course-name h2 {
		font-size: 34px;
	}

	.course-name h6,
	.resources h6,
	.Objectives h6 {
		font-size: 24px;
	}

	.course-detail h4 {
		font-size: 34px;
	}

	.accordion-item .accordion-button {
		font-size: 14px;
	}

	.accro-detail li a {
		font-size: 16px;
	}

	.accordion-button {
		font-size: 14px;
		font-weight: 500;
	}

	.accordion-button {
		padding: 5px 15px;
	}

	.resources h5 {
		font-size: 18px;
	}

	.resources p {
		font-size: 16px;
	}

	.book a {
		font-size: 24px;
	}

	.goal ul li,
	.case ul li {
		font-size: 14px;
	}

	.video-card .card::before {
		height: 46%;
	}

	.breadcrumb-item a {
		font-size: 14px;
		margin-right: 40px;
	}

	.breadcrumb-item+.breadcrumb-item {
		font-size: 14px;
	}

}

@media (max-width: 1300px) {
	.container {
		max-width: 1140px;
	}

	.headercontainer .navigation .sf-menu>li {
		margin: 0px 12px;
	}

	.headercontainer .loginbtx a {
		padding: 12px 15px 12px 13px;
	}

	.headercontainer .logoouter {
		max-width: 150px;
	}

}

@media (max-width: 1199px) {
	.container {
		max-width: 100%;
	}

	.headercontainer .container {
		max-width: 100%;
	}

	.footer-sec .row>div {
		width: 40%;
	}

	.footer-sec .row>div:nth-child(2) {
		width: 60%;
	}

	.footer-sec .row>div:nth-child(3) {
		width: 100%;
	}

	.footer-contact .row>div {
		width: 50% !important;
	}

	.footer-contact {
		margin-top: 50px;
	}

	.video-card .card::before {
		height: 45%;
	}




}

@media (max-width: 1140px) {

	.headercontainer .navigation .sf-menu>li {
		margin: 0px 8px;
	}
}

@media (min-width: 992px) {

	.NavBar {
		display: none;
	}

	.NavBar i{
		font-size: 25px;
	}

	.headercontainer .navigation .navuser {
		display: none;
	}

	.headercontainer.sticky {
		position: fixed;
		top: 0 !important;
		z-index: 99;
		left: 0;
		padding: 10px 0px;
		width: 100%;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
		-webkit-animation: stickySlideDown .65s cubic-bezier(.23, 1, .32, 1) both;
		animation: stickySlideDown .65s cubic-bezier(.23, 1, .32, 1) both;
	}

	.headercontainer.sticky .topmailbx {
		display: none;
	}

	.headercontainer.sticky .navigationouter {
		border: none;
		margin: 0px;
		padding: 0px;
	}

	.headercontainer.sticky .navigationouter:after {
		display: none;
	}

	.headercontainer.sticky .navigation .sf-menu>li>a:after {
		bottom: -14px;
	}

	.sf-menu li:hover>ul,
	.sf-menu li.sfHover>ul {
		top: 100%;
		display: block;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)";
		z-index: 9;
	}

	.sf-menu ul {
		background: #fff;
		min-width: 240px;
		width: 240px;
		display: none;
		-webkit-box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.08);
		box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.08);
		-webkit-border-radius: 3px;
		border-radius: 3px;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
		margin: 0px;
		padding: 0px 0px;
		-webkit-transition: all 0.3s ease 0s;
		transition: all 0.3s ease 0s;
	}
}

@media (max-width: 991px) {
	.headercontainer {
		padding: 10px 0px;
	}
	.NavBar i{
		font-size: 25px;
	}
	.headercontainer.sticky {
		position: fixed;
		top: 0 !important;
		z-index: 99;
		left: 0;
		padding: 10px 0px;
		width: 100%;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
	}

	.headercontainer .headtop .logobx {
		float: left;
	}

	.headercontainer .logoouter .logo img {
		max-width: 70px;
		-webkit-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
	}

	.headercontainer .navigation .NavBar {
		float: right;
		font-size: 26px;
		text-decoration: none;
		color: #232323;
	}

	.headercontainer .topmailbx {
		display: none;
	}

	.headercontainer .navigation .navigationbox {
		position: fixed;
		top: 0;
		padding: 0;
		height: 100%;
		left: 0;
		margin-left: -380px;
		background: #fff;
		border: none;
		padding-top: 0px;
		max-height: 100%;
		overflow: auto;
		margin-right: 0;
		transition: all 0.3s;
		width: 380px;
		z-index: 99999;
	}

	.layout-expanded2 .headercontainer .navigation .navigationbox {
		margin-left: 0px;
	}

	.layout-expanded2 {
		overflow: hidden;
	}

	.layoutovarlay2 {
		position: fixed;
		z-index: 1000;
		top: 0;
		right: 0;
		height: 100% !important;
		left: 0;
		display: none;
		background: #181C21;
		opacity: .5;
		cursor: pointer;
	}

	.layout-expanded2 .layoutovarlay2 {
		display: block;
	}

	.headercontainer .navigation .navuser {
		float: left;
		width: 100%;
		padding: 30px 20px 20px 20px;
		text-align: center;
		position: relative;
		background: #fff;
		margin-bottom: 0px;
	}

	.headercontainer .navigation .navuser .userimg {
		position: relative;
		height: 90px;
		display: inline-block;
		text-align: center;
		-webkit-border-radius: 50%;
		border-radius: 50%;
	}

	.headercontainer .navigation .navuser .userimg img {
		position: relative;
		left: 0px;
		top: 0px;
		bottom: 0px;
		right: 0px;
		text-align: center;
		vertical-align: middle;
		margin: auto;
		width: auto;
		max-width: 100%;
		max-height: 100%;
		padding: 0px;
	}

	.headercontainer .navigation .navuser h3 {
		color: #003e32;
		text-align: center;
		margin: 10px 0px 4px 0px;
		font-size: 19px;
		padding: 0px;
		text-transform: capitalize;
	}

	.headercontainer .navigation .navuser p {
		color: #fff;
		text-align: center;
		margin: 0px 0px 8px 0px;
		font-size: 13px;
		padding: 0px;
		font-weight: 400;
	}

	.headercontainer .navigation .sf-menu {
		padding-top: 10px;
	}

	.headercontainer .navigation .sf-menu>li {
		float: left;
		width: 100%;
		margin: 0px;
		padding: 0px;
	}

	.headercontainer .navigation .sf-menu>li+li {
		border-top: 1px solid #ccc;
	}

	.headercontainer .navigation .sf-menu>li a {
		color: #070707 !important;
		float: left;
		width: 100%;
		text-align: left;
		padding: 14px 30px;
		margin: 0px;
	}

	.headercontainer .navigation .sf-menu>li>a:after {
		display: none;
	}

	.headercontainer .navigation .sf-menu>li.active a {
		color: #337AB7;
	}

	.headercontainer .navigationouter {
		padding: 0px;
		border: none;
	}

	.headercontainer .loginbtx {
		margin-left: 10px;
		padding: 0px 0px 0px 0px;
		/* position: absolute; */
		/* right: 45px; */
		/* top: 4px; */
    flex-wrap: wrap;
		align-items: center;
		
	}

	.usrlog {
		margin-right: 0;
	}

	.headercontainer .navigationouter:after {
		display: none;
	}

	.headercontainer .navigation .sf-menu {
		width: 100%;
	}

	.headercontainer .logoouter .logo img {
		max-width: 146px;
	}

	.headercontainer .navigation ul .slidedown {
		position: absolute;
		right: 5px;
		top: 8px;
		z-index: 10;
		font-size: 22px;
		width: 30px;
		height: 30px;
		line-height: 30px;
		color: #fff;
		text-align: center;
		cursor: pointer;
	}

	.headercontainer .navigation ul li ul {
		display: none;
		position: static;
	}

	.baner-cntent {
		max-width: 458px;
	}

	.baner-cntent h2 {
		font-size: 34px;
	}

	.baner-cntent p {
		font-family: 'ClashGrotesk-Regular';
		font-size: 15px;
		color: #fff;
	}

	.bnrsrch input {
		max-width: 130px;
	}

	.bnrsrch select {
		width: 108px;
	}

	.baner-cntent {
		padding-right: 100px;
	}

	.banner-sec {
		min-height: 260px;
	}

	.video-card .card::before {
		height: 48%;
	}

	.video-card .card-body a {
		width: 220px;
		height: 50px;
	}

	.course-name h2 {
		font-size: 28px;
	}

	.course-name h6,
	.resources h6,
	.Objectives h6 {
		font-size: 20px;
	}

	.accro-detail li a {
		font-size: 14px;
	}

	.course-detail h4 {
		font-size: 28px;
	}

	.resources h5 {
		font-size: 16px;
	}

	.resources p {
		font-size: 14px;
	}

	.reso-data {
		padding: 10px;
	}

	.book a {
		font-size: 20px;
	}

	.video-card .card-title {
		font-size: 20px;
	}

	.video-card .card-body p {
		font-size: 14px;
		line-height: 20px;
	}

	.personal-goal h6,
	.Case-exercise h6,
	.work-book h6,
	.Virtual-mentoring h6 {
		font-size: 20px;
	}

	.personal-goal p,
	.Case-exercise p,
	.book p,
	.Virtual-mentoring p {
		font-size: 14px;
	}

	.goal ul li,
	.case ul li {
		font-size: 14px;
	}

	.goal ul li,
	.case ul {
		padding-left: 1rem;
	}

	.personal-goal,
	.Case-exercise,
	.work-book,
	.Virtual-mentoring {
		padding: 12px;
		margin-top: 20px;
	}

	.instruction {
		padding-left: 0px;
	}

	.sf-menu li ul li+li {
		border-top: 1px solid #fff;
	}

	.headercontainer .navigation .sf-menu>li ul {
		background-color: #fff;
	}
	/* .googletranslateelement {
		display: none;
	} */
}

@media (max-width: 768px) {}

@media (max-width: 821px) {
	.video-card .card::before {
		height: 40%;
	}
}

@media (max-width: 768px) {
	.video-card .card::before {
		height: 36%;
	}

	.accro-detail ul {
		padding-left: 1rem;
	}

	.instruction h4::after {
		width: 80px;
	}

	.video-card .card-body a {
		width: 200px;
	}
}

@media (max-width: 1025px) {
	.video-card .card::before {
		height: 39%;
	}
}

@media (max-width: 767px) {
	.headercontainer .navigation .sf-menu>li ul {
		width: 100%;
	}

	.headercontainer .logoouter .logo img {
		max-width: 120px;
	}

	.headercontainer .loginbtx {
		top: 0px;
	}

	.baner-cntent {
		max-width: 100%;
		padding: 15px 0 20px;
	}

	.nula-sec {
		margin-bottom: 20px;
	}

	.nuladetail p {
		font-size: 18px;
	}

	.headercontainer .rgistrbtn span {
		margin-right: 1px;
	}

	.headercontainer .loginbtx a {
		padding: 12px 10px 12px 10px;
	}

	.usrlog span {
		width: 32px;
		height: 32px;
	}

	.footer-sec .row>div {
		width: 100%;
	}

	.footer-sec .row>div:nth-child(2) {
		width: 100%;
	}

	.fotlink {
		padding-left: 4px;
	}

	.footer-contact .row>div {
		width: 100% !important;
	}

	.footer-contact .footer-contact .row>div {
		padding-left: 15px;
	}

	.banner-sec {
		min-height: inherit;
		display: block;
	}

	.banner-info {
		position: relative;
		background: #337AB7;
	}

	.banner-pic {
		width: 100%;
	}

	.banner-form {
		display: none;
	}

	.below-inner {
		display: block;
		text-align: center;
	}

	.below-inner ul {
		padding: 12px 0 0;
	}

	.below-inner ul li a {
		margin: 0 9px;
	}

	.mb-78 {
		margin-bottom: 20px;
	}

	.footer-sec {
		padding: 40px 0 0 0;
	}

	.foterlogo p {
		font-size: 15px;
	}

	.reso-data {
		padding: 35px 10px;
	}

	.video-card .card::before {
		height: 74%;
	}

}

@media (max-width: 575px) {
	.headercontainer .logoouter {
		-ms-flex: 0 0 100px;
		flex: 0 0 100px;
		max-width: 100px;
	}
	.NavBar i{
		font-size: 25px;
	}
	.headercontainer .loginbtx a {
		font-size: 12px;
	}

	.headercontainer .navigation .navigationbox {
		margin-left: -100%;
		width: calc(100% - 50px);
	}

	.layout-expanded2 .headercontainer .navigation .navigationbox {
		margin-left: 0px;
	}

	.headercontainer .loginbtx a {
		padding: 11px 10px 10px 10px;
	}

	.baner-cntent h2 {
		font-size: 28px;
	}

	.footer-contact h4 {
		font-size: 22px;
		margin-bottom: 30px;
	}

	.fotlink h4 {
		font-size: 22px;
		margin-bottom: 20px;
		margin-top: 10px;
	}

	.resources .img-fluid {
		width: 100%;
	}

	.accro-detail ul {
		padding-left: 1rem;
	}

	.accro-detail li a {
		font-size: 16px;
	}

	.accordion-item .accordion-button {
		font-size: 18px;
	}

	.resources h5 {
		font-size: 20px;
	}

	.resources p {
		font-size: 20px;
	}

	.video-card .card::before {
		height: 62%;
	}

	.video-card .card-body p {
		font-size: 20px;
		line-height: 30px;
	}

	.personal-goal h6,
	.Case-exercise h6,
	.work-book h6,
	.Virtual-mentoring h6 {
		font-size: 24px;
	}

	.personal-goal p,
	.Case-exercise p,
	.book p,
	.Virtual-mentoring p {
		font-size: 16px;
	}

	.goal ul li,
	.case ul li::before {
		left: -3%;
	}

	.personal-goal p,
	.Case-exercise p,
	.book p,
	.Virtual-mentoring p {
		font-size: 20px;
	}

	.Objectives-list ul li {
		font-size: 18px;
	}

	.loginfrom {
		width: 100%;
	}

	/* .googletranslateelement {
		display: none;
	} */

}

@media (max-width: 440px) {

	.headercontainer .logoouter .logo img {
		max-width: 102px;
	}

	.course-name h2 {
		font-size: 22px;
	}

	.course-name h6,
	.resources h6,
	.Objectives h6 {
		font-size: 16px;
	}

	.course-detail h4 {
		font-size: 22px;
	}

	.accro-detail li:nth-child(odd)::before {
		left: -7%;
	}

	.accro-detail li:nth-child(even)::before {
		left: -7%;
	}

	.accordion-item .accordion-button {
		font-size: 16px;
	}

	.resources h5 {
		font-size: 16px;
	}

	.resources p {
		font-size: 16px;
	}

	.reso-data {
		padding: 15px 10px;
	}

	.video {
		padding: 0px 0px 20px 0px;
	}

	.book a {
		font-size: 16px;
	}

	.Objectives-list ul li {
		font-size: 14px;
	}

	.video-card .card::before {
		height: 56%;
	}

	.video-card .card-title {
		font-size: 20px;
	}

	.video-card .card-body p {
		font-size: 16px;
		line-height: 25px;
	}

	.personal-goal h6,
	.Case-exercise h6,
	.work-book h6,
	.Virtual-mentoring h6 {
		font-size: 20px;
	}

	.personal-goal p,
	.Case-exercise p,
	.book p,
	.Virtual-mentoring p {
		font-size: 16px;
	}

}