@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');

body {
	font-family: 'ClashGrotesk-Semibold';
	font-size: 14px;
	background: #fff;
}

@font-face {
	font-family: 'ClashGrotesk-Variable';
	src: url('../fonts/ClashGrotesk-Variable.woff2') format('woff2'),
		url('../fonts/ClashGrotesk-Variable.woff') format('woff'),
		url('../fonts/ClashGrotesk-Variable.ttf') format('truetype');
	font-weight: 200 700;
	font-display: swap;
	font-style: normal;
}


@font-face {
	font-family: 'ClashGrotesk-Extralight';
	src: url('../fonts/ClashGrotesk-Extralight.woff2') format('woff2'),
		url('../fonts/ClashGrotesk-Extralight.woff') format('woff'),
		url('../fonts/ClashGrotesk-Extralight.ttf') format('truetype');
	font-weight: 200;
	font-display: swap;
	font-style: normal;
}


@font-face {
	font-family: 'ClashGrotesk-Light';
	src: url('../fonts/ClashGrotesk-Light.woff2') format('woff2'),
		url('../fonts/ClashGrotesk-Light.woff') format('woff'),
		url('../fonts/ClashGrotesk-Light.ttf') format('truetype');
	font-weight: 300;
	font-display: swap;
	font-style: normal;
}


@font-face {
	font-family: 'ClashGrotesk-Regular';
	src: url('../fonts/ClashGrotesk-Regular.woff2') format('woff2'),
		url('../fonts/ClashGrotesk-Regular.woff') format('woff'),
		url('../fonts/ClashGrotesk-Regular.ttf') format('truetype');
	font-weight: 400;
	font-display: swap;
	font-style: normal;
}


@font-face {
	font-family: 'ClashGrotesk-Medium';
	src: url('../fonts/ClashGrotesk-Medium.woff2') format('woff2'),
		url('../fonts/ClashGrotesk-Medium.woff') format('woff'),
		url('../fonts/ClashGrotesk-Medium.ttf') format('truetype');
	font-weight: 500;
	font-display: swap;
	font-style: normal;
}


@font-face {
	font-family: 'ClashGrotesk-Semibold';
	src: url('../fonts/ClashGrotesk-Semibold.woff2') format('woff2'),
		url('../fonts/ClashGrotesk-Semibold.woff') format('woff'),
		url('../fonts/ClashGrotesk-Semibold.ttf') format('truetype');
	font-weight: 600;
	font-display: swap;
	font-style: normal;
}


@font-face {
	font-family: 'ClashGrotesk-Bold';
	src: url('../fonts/ClashGrotesk-Bold.woff2') format('woff2'),
		url('../fonts/ClashGrotesk-Bold.woff') format('woff'),
		url('../fonts/ClashGrotesk-Bold.ttf') format('truetype');
	font-weight: 700;
	font-display: swap;
	font-style: normal;
}



/* html, body{} */


@font-face {
	font-family: 'icomoon';
	src: url('../fonts/icomoon.eot?ai2qi2');
	src: url('../fonts/icomoon.eot?ai2qi2#iefix') format('embedded-opentype'),
		url('../fonts/icomoon.ttf?ai2qi2') format('truetype'),
		url('../fonts/icomoon.woff?ai2qi2') format('woff'),
		url('../fonts/icomoon.svg?ai2qi2#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.headercontainer {
	padding: 15px 0px !important;
}

.icon-linkedin:before {
	content: "\e906";
}

.icon-youtube:before {
	content: "\e90a";
}

.icon-brand:before {
	content: "\e90a";
}

.icon-social:before {
	content: "\e90a";
}

.icon-star-empty:before {
	content: "\e9d7";
}

.icon-usr:before {
	content: "\e90c";
}

.icon-edit:before {
	content: "\e90d";
}

.icon-fb:before {
	content: "\e903";
}

.icon-insta:before {
	content: "\e905";
}

.icon-lftarw:before {
	content: "\e907";
}

.icon-rtarw:before {
	content: "\e908";
}

.icon-srch:before {
	content: "\e909";
}

.icon-twtr:before {
	content: "\e90b";
}

.icon-eml:before {
	content: "\e900";
}

.icon-cll:before {
	content: "\e901";
}

.icon-loctn:before {
	content: "\e902";
}

.icon-segment:before {
	content: "\e916";
}

.icon-Star:before {
	content: "\e904";
}

.icon-usr:before {
	content: "\e90c";
}

.icon-edit:before {
	content: "\e90d";
}

.icon-fb:before {
	content: "\e903";
}

.icon-insta:before {
	content: "\e905";
}

.icon-lftarw:before {
	content: "\e907";
}

.icon-rtarw:before {
	content: "\e908";
}

.icon-srch:before {
	content: "\e909";
}

.icon-twtr:before {
	content: "\e90b";
}

.icon-eml:before {
	content: "\e900";
}

.icon-cll:before {
	content: "\e901";
}

.icon-loctn:before {
	content: "\e902";
}

.icon-segment:before {
	content: "\e916";
}

.icon-Star:before {
	content: "\e904";
}

.icon-Down_Arrow_3:before {
	content: "\e90e";
	color: #bdbdbd;
}

.icon-Vector:before {
	content: "\e90f";
	color: #7d7d7d;
}



.tophead {
	display: flex;
	justify-content: space-between;
	align-items: center;
}


@-webkit-keyframes stickySlideDown {
	from {
		transform: translateY(-100%);
	}

	to {
		transform: translateY(0);
	}
}

@keyframes stickySlideDown {
	from {
		transform: translateY(-100%);
	}

	to {
		transform: translateY(0);
	}
}

:focus {
	outline: none !important;
}

button:focus {
	outline: none !important
}

.wrapper {
	max-width: 1950px;
	position: relative;
	margin: 0 auto;
	height: 100%;
	overflow: hidden;
}

.carrun {
	position: absolute;
	max-width: 184px;
	height: 100%;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
}

.carrun::before {
	width: 2px;
	background: #CDCDCD;
	content: '';
	height: 100%;
	position: absolute;
	left: 32%;
}

.carpic {
	width: 100%;
	text-align: center;
}

.carpic img {
	width: auto;
	height: auto;
	max-width: 100%;
	position: relative;
	animation: topToBottom 3s linear infinite;
	margin-bottom: 400px;
}

@keyframes topToBottom {
	0% {
		transform: translateY(-100%);
	}

	100% {
		transform: translateY(100%);
	}
}

.headercontainer {
	padding: 10px 0px;
	background: #fff;
	position: relative;
	z-index: 9;
}

.tophead {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.headercontainer .logoouter {
	max-width: 271px;
	padding: 0px 0;
}

.headercontainer .logoouter .logo {
	float: left;
	width: 100%;
}

.headercontainer .logoouter .logo img {
	max-width: 100%;
	max-height: 100%;
}

.headercontainer .topmailbx {
	float: left;
	width: 100%;
	padding-bottom: 20px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-ms-flex-align: center;
	align-items: center;
}

.headercontainer .topmailbx a {
	color: #1E1E1E;
	font-size: 15px;
	font-weight: 300;
	letter-spacing: 1px;
	text-decoration: none;
}

.headercontainer .topmailbx a+a {
	margin-left: 35px;
}

.headercontainer .topmailbx a i {
	display: inline-block;
	vertical-align: middle;
	margin-right: 5px;
}

.headercontainer .topmailbx a span {
	display: inline-block;
	vertical-align: middle;
}

.headercontainer .navigation {
	width: auto;
	display: flex;
}

.headercontainer .navigation .navigationbox {
	float: left;
}

.headercontainer .navigation .sf-menu-outer {
	float: left;
	width: 100%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-ms-flex-align: center;
	align-items: center;
}

.headercontainer .navigationouter {
	float: left;
	width: 100%;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-ms-flex-align: center;
	align-items: center;
	position: relative;
}

.headercontainer .navigation .sf-menu {
	float: left;
	margin: 0px;
	padding: 0px;
}

.headercontainer .navigation .sf-menu>li {
	float: none;
	display: inline-block;
	margin: 0px 20px;
}

.headercontainer .navigation .sf-menu>li a {
	position: relative;
	font-family: 'ClashGrotesk-Medium';
	color: #070707;
	text-transform: capitalize;
	display: block;
	font-size: 20px;
	text-transform: uppercase;
	letter-spacing: 2px;
	text-decoration: none;
	-webkit-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
}


.headercontainer .navigation .sf-menu>li.active a {
	color: #337AB7;
}

.headercontainer .navigation .sf-menu>li.active a:after {
	opacity: 1;
}

.headercontainer .navigation .sf-menu>li a:hover {
	color: #337AB7;
}

.headercontainer .navigation .sf-menu>li a:hover:after {
	opacity: 1;
}

.headercontainer .loginbtx {
	margin-left: 50px;
	display: flex;
	align-items: center;
}

.headercontainer .rgistrbtn {
	padding: 14px;
	border: none;
	border-radius: 50px;
	font-size: 16px;
	background: linear-gradient(134deg, #2D70A1 0%, #60A3C5 100%);
	color: #fff;
	border: 2px solid #fff;
	position: relative;
	font-family: 'ClashGrotesk-Medium';
	text-decoration: none;
}

.headercontainer .rgistrbtn:hover {
	background: linear-gradient(134deg, #60A3C5 0%, #2D70A1 100%);
	color: #fff !important;
}

.headercontainer .rgistrbtn span {
	margin-right: 10px;
}

.headercontainer .rgistrbtn:hover {
	color: #000;
}

.headercontainer .rgistrbtn i {
	color: #fff;
	margin-right: 6px;
}

.headercontainer .rgistrbtn em {
	font-style: normal;
}

.headercontainer .rgistrbtn span {
	color: #fff;
	padding: 0px 6px;
}

.usrlog {
	color: #232323;
	font-size: 18px;
	font-family: 'ClashGrotesk-Medium';
	display: inline-flex;
	align-items: center;
	margin-right: 20px;
	text-decoration: none;
}

.usrlog:hover {
	color: #2D70A1;
}

.usrlog span {
	border-radius: 50%;
	width: 38px;
	height: 38px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 6px;
	font-size: 14px;
	color: #2D70A1;
	border: 2px solid #2D70A1;
}

/* dropdown menu css start */
.headercontainer .navigation ul li ul li {
	width: 100%;
}

.headercontainer .navigation ul li ul li a {
	width: 100%;
	font-size: 13px;
}

.sf-menu,
.sf-menu {
	margin: 0;
	padding: 0;
	list-style: none;
}

.sf-menu li {
	position: relative;
}

.sf-menu ul {
	position: absolute;
	top: 100%;
	left: 0px;
	z-index: 999;
	padding: 0px;
	margin: 0px;
}

.sf-menu>li {
	float: left;
}

.sf-menu a {
	display: block;
	position: relative;
}

.sf-menu ul ul {
	top: 0;
	left: 100%;
}

/* DEMO SKIN */
.sf-menu {
	float: left;
}

.sf-menu li ul li a {
	background: 0 0;
	color: #4B9095;
	text-align: left;
	padding: 12px 15px;
}

.sf-menu li ul li+li {
	border-top: 1px solid #eaeaea;
}

.sf-menu li ul li a:hover {
	background: #000;
	color: #ffffff;
}

.sf-menu a {
	text-decoration: none;
	zoom: 1;
}

.sf-menu a {
	color: #13a;
}

.sf-menu li {
	-webkit-transition: background .2s;
	transition: background .2s;
}

.sf-menu li:hover,
.sf-menu li.sfHover {
	-webkit-transition: none;
	transition: none;
}

.headercontainer .navigation .sf-menu>li>a:hover {
	color: #337AB7;
	background: 0 0;
}

.headercontainer .navigation .sf-menu>li:hover a {
	background: transparent;
	color: #337AB7;
}

.headercontainer .navigation .sf-menu>li ul li:hover a {
	background: none !important;
	color: #303030;
}

.headercontainer .navigation .sf-menu>li:hover ul li a {
	color: #000000;
}

.headercontainer .navigation .sf-menu>li ul li a {
	background: none !important;
	color: #ffffff;
	-webkit-transition: 0.4s ease-in-out;
	-moz-transition: 0.4s ease-in-out;
	-ms-transition: 0.4s ease-in-out;
	-o-transition: 0.4s ease-in-out;
	transition: 0.4s ease-in-out;
}

.headercontainer .navigation .sf-menu>li ul li a:hover {
	background: #3498db !important;
	color: #ffffff;
}

.headercontainer .navigation .sf-menu>li ul li.active a {
	background: #3498db !important;
	color: #ffffff;
}

.headercontainer .navigation .sf-menu>li ul li a {
	background: none;
	font-size: 16px;
	text-transform: capitalize;
}

.sf-arrows .sf-with-ul {
	padding-right: 30px !important;
}

.sf-arrows .sf-with-ul:after {
	content: '\f107';
	position: absolute;
	font-family: 'FontAwesome';
	right: 20px;
	height: 0;
	width: 0;
}

.sf-arrows>li>.sf-with-ul {
	padding-right: 30px !important;
}

.sf-arrows>li>.sf-with-ul:focus:after,
.sf-arrows>li:hover>.sf-with-ul:after,
.sf-arrows>.sfHover>.sf-with-ul:after {}

.sf-arrows ul .sf-with-ul:after {
	content: '\f105';
}

.sf-arrows ul li>.sf-with-ul:focus:after,
.sf-arrows ul li:hover>.sf-with-ul:after,
.sf-arrows ul .sfHover>.sf-with-ul:after {}

.rgistrbx {
	display: flex;
}

/* Dropdown Nav */
.headercontainer .navigation .sf-menu>li {
	position: relative;
}

.headercontainer .navigation ul li.parent>a:after {
	right: 1px;
}

.headercontainer .navigation ul li.parent>a:hover:after {
	color: #ffd1e2;
}

.headercontainer .navigation .sf-menu>li:hover a:after {
	color: #ffd1e2;
}

.headercontainer .navigation .sf-menu>li ul {
	background: #337AB7;
	-webkit-border-radius: 0px;
	border-radius: 0px;
}

.headercontainer .navigation .sf-menu>li ul li {
	list-style: none;
}

.headercontainer .navigation .sf-menu>li ul li a {
	color: #fff;
}

.headercontainer .navigation .sf-menu>li:hover ul li a {
	color: #fff;
}

.headercontainer .navigation .sf-menu>li ul li+li {
	/* border-color: #a25d76; */
}

.headercontainer .navigation .sf-menu>li ul li a:hover {
	background: #000 !important;
}



.banner-sec {
	background: #E3E4E7;
	width: 100%;
	min-height: 360px;
	position: relative;
	display: flex;
}

.banner-sec .container {
	position: relative;
}

.banner-pic {
	width: 60%;
}

.banner-form {
	width: 40%;
	background: #337AB7;
}

.baner-cntent {
	position: relative;
	left: 0;
	top: 0;
	height: 100%;
	max-width: 740px;
	width: 100%;
	z-index: 1;
	display: flex;
	align-items: center;
	padding-right: 140px;
	padding-top: 15px;
	padding-bottom: 15px;
}

.banner-info {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.banner-info .container {
	height: 100%;
}

.baner-cntent:before {
	clip-path: polygon(0 0, 100% 0, 83% 100%, 0% 100%);
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: #337AB7;
	content: '';
	z-index: -1;
}

.baner-cntent h2 {
	font-family: 'ClashGrotesk-Medium';
	font-size: 55px;
	color: #fff;
}

.baner-cntent p {
	font-family: 'ClashGrotesk-Medium';
	font-size: 22px;
	color: #fff;
}


.bnrsrch {
	padding: 5px 50px 4px 20px;
	background: #FFFFFF;
	border: none;
	height: 50px;
	color: #72775a;
	font-style: italic;
	font-size: 14px;
	border-radius: 50px;
	box-shadow: none;
	display: flex;
}

.bnrsrch select {
	padding: 0;
	background: #fff;
	border: none;
	width: 120px;
	font-weight: 700 !important;
	outline: none;
}

.bnrsrch input {
	padding: 0 0 0 15px;
	background: #fff;
	border: none;
	border-left: 1px solid #666;
	margin-left: 10px;
	max-width: 320px;
	width: 100%;
}

.form-group {
	position: relative;
	margin: 0;
	max-width: 638px;
	width: 100%;
}

.form-group .searchbtn {
	border: none;
	outline: none;
	position: absolute;
	right: 6px;
	top: 50%;
	width: 40px;
	height: 40px;
	line-height: 40px;
	font-size: 20px;
	background: #2D70A1;
	color: #fff;
	border-radius: 50%;
	transform: translateY(-50%);
}

.main-boy-sec {
	padding: 50px 0;
}

.nula-sec {
	position: relative;
	width: 100%;
	border-radius: 5px;
	overflow: hidden;
	margin-bottom: 40px;
	padding-bottom: 12px;
}

.nula-sec img {
	width: 100%;
	border-radius: 5px;
}

.pluslink {
	position: absolute;
	bottom: -1px;
	right: -19px;
}

.nula-sec:before {
	content: '';
	position: absolute;
	left: 0;
	bottom: 12px;
	width: 100%;
	height: 80%;
	border-radius: 5px;
	background: linear-gradient(360deg, #000 0%, rgba(0, 0, 0, 0.00) 100%);
}

.nuladetail {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	padding: 0 60px 20px 15px;
}

.nuladetail p {
	font-family: 'ClashGrotesk-Semibold';
	font-size: 20px;
	color: #fff;
}


/* footer section */
.footer-sec {
	background: #F0F8FF;
	padding: 80px 0 0 0;
	position: relative;
}

.footer-sec .container {
	position: relative;
}

.foterlogo {
	position: relative;
	text-align: left;
}

.foterlogo a {
	display: block;
	margin-bottom: 30px;
}

.hdrsocial {
	padding: 0;
	display: inline-flex;
	align-items: center;
	width: 100%;
	margin-top: 15px;
}

.hdrsocial li {
	margin-right: 10px;
	list-style-type: none;
}

.hdrsocial li a {
	display: inline-block;
}

.hdrsocial li img {
	width: auto;
	height: auto;
}

.foterlogo p {
	font-family: 'ClashGrotesk-Medium';
	font-style: normal;
	font-size: 18px;
	color: #2E2E2E;
	margin-top: 15px;
}

.trmslnk {
	padding: 0;
}

.trmslnk li {
	list-style-type: none;
	display: inline-block;
	color: #2E2E2E;
	font-size: 16px;
	padding: 5px 8px;
}

.trmslnk li:first-child {
	padding-left: 0;
}

.trmslnk li a {
	font-family: 'ClashGrotesk-Semibold';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 29px;
	letter-spacing: -0.03em;
	text-transform: capitalize;
	color: #2E2E2E;
	text-decoration: none;
}

.fotlink h4 {
	font-family: 'ClashGrotesk-Semibold';
	font-size: 27px;
	color: #222222;
	margin-bottom: 35px;
	display: inline-block;
	position: relative;
	padding-bottom: 20px;
}

.fotlink h4 span {
	position: absolute;
	right: -7px;
	bottom: 0;
}

.fotlink ul {
	padding: 0;
	margin: 0;
	width: 100%;
	display: inline-block;
	text-align: left;
}

.fotlink ul li {
	list-style-type: none;
}

.fotlink ul li a {
	list-style-type: none;
	font-family: 'ClashGrotesk-Medium';
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 29px;
	text-transform: capitalize;
	color: #2E2E2E;
	text-decoration: none;
	padding: 8px 10px 8px 16px;
	display: block;
	position: relative;
}

.fotlink ul li a::before {
	position: absolute;
	width: 6px;
	height: 6px;
	background: #337AB7;
	border-radius: 50%;
	content: '';
	top: 50%;
	left: 0;
}

.fotlink ul li a:hover {
	color: #F60A0A;
}

.footer-contact {
	text-align: left;
}

.footer-contact h4 {
	font-family: 'ClashGrotesk-Semibold';
	font-size: 27px;
	color: #222222;
	margin-bottom: 35px;
	display: inline-block;
	position: relative;
	padding-bottom: 20px;
}

.footer-contact h4 span {
	position: absolute;
	right: -7px;
	bottom: 0;
}

.footer-contact .usetmtx {
	float: left;
	text-decoration: none;
}

.footer-contact .usetmtx .usetmtxicon {
	display: table-cell;
	vertical-align: middle;
}

.footer-contact .usetmtx .usetmtxicon i {
	float: left;
	background: #F0F8FF;
	color: #337AB7;
	font-size: 19px;
	width: 54px;
	height: 54px;
	text-align: center;
	border-radius: 5px;
	overflow: hidden;
	border: 2px solid #337AB7;
	display: flex;
	align-items: center;
	justify-content: center;
}

.footer-contact .usetmtx .usetmtxtext {
	display: table-cell;
	vertical-align: middle;
	padding-left: 15px;
	color: #2E2E2E;
	font-size: 17px;
	max-width: 266px;
	font-family: 'ClashGrotesk-Medium';
}

.footer-contact .usetmtx .nmbtxt {
	display: table-cell;
	vertical-align: middle;
	padding-left: 15px;
	color: #2E2E2E;
	font-size: 23px;
	font-family: 'ClashGrotesk-Medium';
}

.mb-78 {
	margin-bottom: 60px;
}



.below-footer {
	background: #337AB7;
	padding: 15px 0;
	color: #fff;
}

.below-inner {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.below-inner p {
	margin: 0;
	font-family: 'ClashGrotesk-Medium';
	font-size: 15px;
}

.below-inner ul {
	padding: 0;
	margin: 0;
}

.below-inner ul li {
	list-style-type: none;
	display: inline-block;
	padding: 0;
	margin: 0;
}

.below-inner ul li a {
	font-size: 20px;
	color: #fff;
	margin: 0 15px;
	text-decoration: none;
}

.containercls {
	max-width: 1240px !important;
}

.banner-inner {
	text-align: left;
}

.banner-inner h2{
	line-height: 60px;
}

.banner-inner p{
	line-height: 25px;
}
.nula-sec img {
	height: 321px;
	object-fit: cover;
}


/* FAQ Section */
.faq_section {
	position: relative;
}

.faq_heading,
.faq_button {
	text-align: center;
}

.faq_heading h2 {
	font-family: 'Jost';
	font-style: normal;
	font-weight: 500;
	font-size: 59px;
	line-height: 85px;
	leading-trim: both;
	text-edge: cap;
	letter-spacing: -0.08em;
	text-transform: uppercase;
	color: #000000;
}

.accordion {
	margin: 30px auto 50px auto;
	width: 80%;
}

.accordion-button {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	border-top-left-radius: 6px !important;
	border-top-right-radius: 6px !important;
	background: #FFFFFF !important;
	box-shadow: none !important;
	color: #000000 !important;
}

.accordion-item {
	box-shadow: 0px 44px 214px rgba(0, 0, 0, 0.08);
	border-radius: 6px;
	margin-bottom: 10px;
	border: 0;
}

.accordion-body {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	leading-trim: both;
	text-edge: cap;
	color: #000000;
	padding: 35px;
}

.accordion-body ul li {
	margin-bottom: 20px;
	list-style: none;
	position: relative;
	padding: 0 30px;
}

.accordion-body ul {
	column-count: 2;
	margin-bottom: 0;
	padding-left: 0;
}

.accordion-button:focus {
	box-shadow: none;
}

.accordion-button::after {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	right: 20px;
	transition: all 0.3s ease-in;
}

.accordion-button:not(.collapsed)::after {
	transform: inherit;
	transition: all 0.3s ease-in;
}

/* FAQ Ends */

.inner_page .page-heading {
	text-transform: uppercase;
	margin: 10px 0px 0px 0px;
}

.contactusform input {
	color: #22262E;
	font-size: 15px;
	border: 0px;
	border-radius: 0px;
	outline: none;
	height: 100%;
	padding: 15px 20px;
	width: 100%;
	resize: none;
	-webkit-appearance: none;
	-webkit-box-shadow: 3px 3px 2px 0px rgb(0 0 0 / 18%);
	box-shadow: 3px 3px 2px 0px rgb(0 0 0 / 18%);
	border-radius: 50px;
}

.contactusform textarea {
	color: #22262E;
	font-size: 15px;
	border: 0px;
	border-radius: 0px;
	outline: none;
	height: 200px;
	padding: 15px 12px;
	width: 100%;
	resize: none;
	-webkit-appearance: none;
	-webkit-box-shadow: 3px 3px 2px 0px rgb(0 0 0 / 18%);
	box-shadow: 3px 3px 2px 0px rgb(0 0 0 / 18%);
}

.contactusform .submitbtn {
	float: left;
}

.submit_button {
	padding: 7px 40px;
	text-decoration: none;
}

.submit_button {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 30px;
	leading-trim: both;
	text-edge: cap;
	letter-spacing: -0.05em;
	color: #FFFFFF;
	background: #2D70A1;
	border-radius: 30px;
	padding: 7px 60px;
	display: inline-block;
	transition: all 0.3s ease-in;
	margin-top: 5px;
}

.submit_button:hover {
	background: #193F76;
	transition: all 0.3s ease-in;
	text-decoration: none;
	color: #FFFFFF;
}

.loginlayer {
	width: 60% !important;
	text-align: center;
	margin: auto;
	padding: 20px 0px;
}

.usercolumn {
	position: relative;
	float: left;
	width: 100%;
	background: transparent;
	padding: 0 25px 18px;
	text-align: center;
}

.usercolumn:after {
	content: "";
	border-bottom: 1px solid #6361AC;
	position: absolute;
	left: 25px;
	right: 25px;
	bottom: 0px;
}

.profileleftpanel {
	float: left;
	width: 100%;
	background: linear-gradient(134deg, #2D70A1 0%, #60A3C5 100%);
	padding: 30px 0 20px;
	border-radius: 0;
	box-shadow: none;
	height: 90vh;
}

.profileleftpanel .userimg img {
	width: 130px;
	height: 130px;
	border-radius: 50%;
	border: 2px solid #fff;
	padding: 5px;
}

.profileleftpanel .username {
	margin-top: 10px;
	color: #fff;
}

.usernavlist {
	float: left;
	width: 100%;
	margin: 0;
	padding: 15px 0;
}

.usernavlist ul {
	float: left;
	width: 100%;
	margin: 0;
	padding: 0;
}

.usernavlist ul li {
	float: left;
	width: 100%;
	margin: 0;
	padding: 0;
	position: relative;
	list-style: none;
	transition: .3s ease-in-out;
}

.usernavlist ul li.active a {
	color: #fff;
}

.usernavlist ul li a {
	float: left;
	width: 100%;
	font-size: 15px;
	margin: 0;
	padding: 12px 10px 12px 68px;
	color: #34e9bd;
	text-decoration: none;
	position: relative;
	transition: .3s ease-in-out;
	text-align: left;
}

.updatebtn {
	float: left;
}

.rightprofile .bg-warning {
	background-color: #20c997 !important;
}

.rightprofile .listdatacls .card-header {
	background: #2D70A1;
	color: #fff;
}

.rightprofile .listdatacls .card-header .title {
	float: left;
}

.rightprofile .listdatacls .tablecls {
	margin-top: -1px;
}

.rightprofile h3 {
	color: #1e1e1e;
	font-size: 22px;
	font-weight: 500;
	text-transform: capitalize;
	text-align: left;
	margin: 0 0 15px;
	padding: 0;
	position: relative;
}

.usercolumn .userimg .useruploadimg {
	position: absolute;
	right: 75px;
	bottom: 68px;
	width: 30px;
	height: 30px;
	overflow: hidden;
	line-height: 30px;
	font-size: 15px;
	background: #F3F3F3;
	color: #2D70A1;
	border-radius: 50%;
}

.usercolumn .userimg .useruploadimg input[type=file i] {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	opacity: 0;
}

.coursebanner {
	max-height: 238px !important;
	min-height: 238px !important;
}

.coursebanner img {
	max-height: 237px;
	width: 805px;
}

.nodatafound {
	font-size: 25px;
	font-weight: 600;
	padding: 50px;
}

.loadmorebtn {
	padding: 14px;
	border: none;
	border-radius: 50px;
	font-size: 16px;
	background: linear-gradient(134deg, #2D70A1 0%, #60A3C5 100%);
	color: #fff;
	border: 2px solid #fff;
	position: relative;
	font-family: 'ClashGrotesk-Medium';
	text-decoration: none;
	width: 160px !important;
	justify-content: center;
	cursor: pointer;
}

.loginfrom {
	width: 50%;
	margin: auto;
	background: linear-gradient(134deg, #2D70A1 0%, #60A3C5 100%);
	padding: 20px;
	border-radius: 10px;
}

.loginlayer .submitbtn {
	font-size: 14px;
	text-decoration: none;
	color: #fff;
}

.loginlayer .submitbtn:hover {
	color: #000;
	transition: all 0.5s ease-in-out;
}

.loginlayer .submit_button {
	font-size: 14px;
	color: #fff;
	border: 1px solid #fff;
}

.loginfrom .page-heading {
	color: #fff;
}

.loginlayer .submit_button:hover {
	color: #fff;
}

.eye {
	position: relative;
}

.eye .eyeicon {
	padding: 13px 30px;
	position: absolute;
	width: 10%;
	right: 3%;
}

.loginfrom .form-control {
	border-radius: 50px;
}

.leftcontact {
	background: linear-gradient(134deg, #2D70A1 0%, #60A3C5 100%);
	padding: 55px 30px;
	border-radius: 10px;
}

.leftcontact textarea {
	border-radius: 20px;
}

.contactusform .submitbtn {
	border: 1px solid #fff;
}

:focus {
	box-shadow: none !important;
}

.accordion-button {
	background: linear-gradient(134deg, #2D70A1 0%, #60A3C5 100%) !important;
	color: #fff !important;
}

.dasheye {
	position: relative;
}

.dasheye .eyeicon {
	position: absolute;
	right: 20px;
	top: 38px;
}

.breadcrumba {
	border-bottom: 1px solid #ddd;
}

.breadcrumb {
	margin: 10px 0px;
}

.breadcrumb a {
	color: #000;
	text-decoration: none;
}

.breadcrumb a:hover {
	color: #2e71a2;
}

.iner-page {
	max-height: 238px !important;
	min-height: 238px !important;
}

.iner-page img {
	max-height: 238px !important;
	min-height: 238px !important;
	width: 100%;
}

.back {
	margin-top: 30px;
	text-align: right;
}

.below-inner ul li a:hover {
	color: red;
}

/************************course detail 07-09-2023****************************/
.breadcrumb-item a {
	margin-right: 50px;
	text-decoration: none;
	color: #7D7D7D;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	font-family: 'ClashGrotesk-Medium';
}

.breadcrumb-item.active {
	color: #7D7D7D !important;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	font-family: 'ClashGrotesk-Medium';
}

.breadcrumb-item+.breadcrumb-item::before {
	content: "\e90e" !important;
	position: absolute;
	font-family: 'icomoon';
	margin-left: -24px;
}

.breadcrumb-item+.breadcrumb-item {
	padding-left: 0px;
}

.course-name h2 {
	color: #222;
	font-family: 'ClashGrotesk-Medium';
	font-size: 50px;
	font-style: normal;
	font-weight: 500;
	padding: 20px 0px 10px 0;
}

.course-name h6,
.resources h6,
.Objectives h6 {
	color: #337AB7;
	font-family: 'ClashGrotesk-Medium';
	font-size: 33px;
	font-style: normal;
	font-weight: 500;
	margin-bottom: 30px;
}

.course-detail {
	padding-bottom: 20px;
}

.course-detail h4 {
	color: #222;
	font-family: 'ClashGrotesk-Medium';
	font-size: 50px;
	font-style: normal;
	font-weight: 500;
	padding: 20px 0px 0px 0px;
}

.course-detail .courseimg {
	width: 100%;
	object-fit: cover;
	height: 450px;
	;
}

.accordion-item .accordion-button {
	color: #292929;
	font-family: 'ClashGrotesk-Medium';
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	background-color: transparent;
	box-shadow: none;
}

.course-accro .accordion {
	padding: 30px 0px;
	float: left;
	width: 100%;
}

.resources img {
	border-radius: 10px;
}

.resources h5 {
	color: #202020;
	font-family: 'ClashGrotesk-Medium';
	font-size: 23px;
	font-style: normal;
	font-weight: 500;
}

.resources p {
	color: #3A3A3A;
	font-family: 'ClashGrotesk-Medium';
	font-size: 17px;
	font-style: normal;
	font-weight: 400;
	margin-bottom: 0px;
}

.reso-data {
	padding: 30px 10px 30px 20px;
}

.overlayy {
	position: relative;
}

.paly {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.video {
	padding: 0px 0px 30px 0px;
}

.book a {
	color: #337AB7;
	font-family: 'ClashGrotesk-Medium';
	font-size: 33px;
	font-style: normal;
	font-weight: 500;
	margin: 10px 0px 10px 0px;
}

.book a:hover {
	color: red;
}

.book h6 {
	padding: 15px 0px;
}


.Objectives-list ul {
	list-style-type: none;
	padding-left: 0;
}

.Objectives-list ul li {
	color: #292929;
	font-family: 'ClashGrotesk-Medium';
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	margin-bottom: 25px;
}

.Objectives-list ul li span {
	color: #337AB7;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	margin-right: 10px;
}

.instruction {
	padding-left: 40px;
}

.instruction h4 {
	color: #222;
	font-family: 'ClashGrotesk-Medium';
	font-size: 50px;
	font-style: normal;
	font-weight: 500;
	padding-top: 0px;
	position: relative;
}

.instruction h4::after {
	position: absolute;
	content: '';
	width: 110px;
	height: 2px;
	background-color: #337AB7;
	top: 50%;
	right: 0%;
}

.video-card .card {
	border: none;
	background-color: #337AB7;
	position: relative;
	border-radius: 10px;
}

.video-card .card-title {
	color: #FFF;
	font-family: 'ClashGrotesk-Medium';
	font-size: 30px;
	font-style: normal;
	font-weight: 500;
	line-height: 50px;
}

.video-card .card-body p {
	color: #FFF;
	font-family: 'ClashGrotesk-Medium';
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 30px;
}

.video-card .card-body a {
	color: #21649D;
	font-family: 'ClashGrotesk-Medium';
	font-size: 25px;
	background-color: #fff;
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 300px;
	margin: auto;
	height: 80px;
	border: 2px solid #fff;
}

.video-card .card-body a:hover {
	background-color: transparent;
	color: #fff;
	border: 2px solid #fff;
	transition: all 0.5s ease-in-out;
}

.video-card .card img {
	border-radius: 10px 10px 0 0;
	cursor: pointer;
}

.video-card .card .card-img-top {
	position: relative;
}

.video-card .card .card-img-top::before {
	position: absolute;
	content: '';
	width: 100%;
	height: 45%;
	background: rgba(0, 0, 0, 0.30);
	border-radius: 10px 10px 0 0;
	cursor: pointer;
	z-index: 1;
}

.card .paly {
	top: 25%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.personal-goal,
.Case-exercise,
.work-book,
.Virtual-mentoring {
	background-color: #F4FAFF;
	padding: 20px;
	border-radius: 10px;
	margin-top: 30px;
}

.personal-goal h6,
.Case-exercise h6,
.work-book h6,
.Virtual-mentoring h6 {
	color: #337AB7;
	font-family: 'ClashGrotesk-Medium';
	font-size: 30px;
	font-style: normal;
	font-weight: 500;
}

.personal-goal p,
.Case-exercise p,
.book p,
.Virtual-mentoring p {
	color: #3A3A3A;
	font-family: 'ClashGrotesk-Medium';
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	margin-bottom: 10px;
}

.goal ul li {
	color: #292929;
	font-family: 'ClashGrotesk-Medium';
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	list-style-type: none;
	padding-bottom: 10px;
	position: relative;
}

.case ul li {
	color: #292929;
	font-family: 'ClashGrotesk-Medium';
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	list-style-type: none;
	padding-bottom: 10px;
	position: relative;
}

.goal ul {
	padding-left: 1rem;
}

.goal ul li::before {
	position: absolute;
	content: '';
	width: 8px;
	height: 8px;
	background-color: #337AB7;
	border-radius: 50px;
	top: 20%;
	left: -5%;
}

.case ul {
	padding-left: 1rem;
}

.case ul li::before {
	position: absolute;
	content: '';
	width: 8px;
	height: 8px;
	background-color: #337AB7;
	border-radius: 50px;
	top: 20%;
	left: -5%;
}

.book img {
	padding: 25px 0px;
}

.Virtual-mentoring img {
	border-radius: 10px;
	width: 100%;
}

.accordion-button:not(.collapsed)::after {
	background-image: url(../images/up.png);
}

.accordion-button:not(.collapsed) {
	background-color: transparent;
	color: #292929;
}

.accordion-button::after {
	background-image: url(../images/down.png);
}

.accordion-item {
	border-radius: 10px;
	border: 1px solid #337AB7;
	background: #F4FAFF;
	margin-bottom: 20px;
	padding: 10px 15px;
}

.accordion-item:not(:first-of-type) {
	border-top: 1px solid #337ab7;
}


.accordion-button {
	border-radius: 10px;
	padding: 10px 15px;
}

.accordion-body {
	padding-right: 40px;
}

.accordion-item .accordion-button:focus {
	box-shadow: none;
}

.accro-detail ul li {
	list-style-type: none;
	position: relative;
	padding: 10px 0px;
}

.accro-detail li p {
	margin-bottom: 0px;
}

.accro-detail li a {
	color: #292929;
	font-family: 'ClashGrotesk-Medium';
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	justify-content: space-between;
	text-decoration: none;
	margin-left: 10px;
}

.accro-detail li:nth-child(odd)::before {
	content: '';
	position: absolute;
	background-image: url(../images/pdff.png);
	width: 19px;
	height: 19px;
	left: -4%;
	top: 30%;
}

.accro-detail li:nth-child(even)::before {
	content: '';
	position: absolute;
	/* background-image: url(../images/video.png); */
	background-image: url(../images/pdff.png);
	width: 17px;
	height: 19px;
	left: -4%;
	top: 30%;
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
	background-color: linear-gradient(134deg, #2D70A1 0%, #60A3C5 100%);
	;
}